.flavour-card-button{

    width: 172px;
    height: 67px;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    color: rgb(22, 20, 20);
    text-transform: capitalize;
    font-family: var(--font-inter);
    font-size: 18px;
    font-weight: 600;
    margin: 2rem;
    /* text-overflow: wrap; */
    padding-left: 20px;
    padding-right: 20px;
}

.red{
    background: #D00000;
}

.orange{
    background: #FF3B00;
}

.mustard{
    background: #FF8933;
}

.yellow{
    background: #FFC123;
}

.green{
    background-color: #A0D800;
}