@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Shantell+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap');

.app__navbar{
    width: 100%;
    height: auto;

    display: flex;
    /* border: 2px solid black; */
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 6rem;
    padding-right: 6rem;
    /* padding: 0.2rem 5rem; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* position: fixed;
    top: 0; */
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.app__navbar-logo img{
    width: 50%;
}

.app__navbar-logo h1{
    font-family: 'Shantell Sans', cursive;
    
    /* Created with https://www.css-gradient.com */
    /* color: conic-gradient(from 315deg, #83FF6F, #F26C24); */
    background-image: linear-gradient(45deg, #CA571E, #C22F41);
    background-clip: text;
    -webkit-text-fill-color:transparent;
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app__navbar-links li{
    margin: 0 2rem;
    cursor: pointer;
    font-weight: 800;
    font-size: 20px;
    color: #fa8d6c;

}

.custom-link{
    color: #FF5623;
    font-weight: 800;
    font-size: 20px;
}

/* .app__navbar-links li:hover{
    color: var(--color-orange);
    border-bottom: 2px solid var(--color-orange);

} */
/* 
.app__navbar-links li:active{
    color: var(--color-orange);
    border-bottom: 2px solid var(--color-orange);
} */

.link-tab{
    font-weight: 600;
}


.app__navbar-links li.active{
    color: var(--color-orange);
    border-bottom: 3px solid #b3e724;
}
.link-tab:hover{
    /* color: var(--color-orange); */
    border-bottom: 2px solid var(--color-orange);

}


.app__navbar-button, .app__navbar-smallscreen_button{
    /* background-image:  linear-gradient(to right, #b3e724 0%, #FF5623  51%, #e4a11b  100%); */
    background-color: #FF5623;
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
}


.app__navbar-button:hover{
    cursor: pointer;
    /* background-color: var(--color-orange); */
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.app__navbar-button:active{
    
    height: 40px;
}

.app__navbar-smallscreen{
    display: none;
}

.app__navbar-smallscreen .overlay__open:hover{
    cursor: pointer;

}

.app__navbar-smallscreen_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color:  rgb(219, 212, 212);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}
.app__navbar-smallscreen_links{
    list-style: none;
    
}

.app__navbar-smallscreen_links li{
    margin: 2rem;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #fc7b54;
    
}

.app__navbar-smallscreen_links li:hover{
    color: var(--color-orange);
    border-bottom: 2px solid var(--color-orange);
    
}
@media screen and (max-width:2000px) {
    /* .app__navbar-logo img{
        width: 90px;
    } */
}

@media screen and (max-width:1150px) {
    .app__navbar-links{
        display: none;
    }
    
    .app__navbar-smallscreen{
        display: flex;
    }
    .app__navbar-button{
        display: none;
    }
    
}
@media screen and (max-width:650px) {
    .app__navbar{
        padding: 1rem;
    }

    .app__navbar-button{
        display: none;
    }

    .app__navbar-logo{
        width: 110px;
    }
}