.main-map-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F2C872;
    /* background-position: 400px 0px; */
    background-image: url('../../../assets/newheader.png');
    /* background-repeat: no-repeat; */
    height: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    
}

.map-container{
    width: 100%;
    height: 300px;
    /* border-bottom: 3px solid #646363; */
}

.outer-container{

    margin-bottom: 30px;
    background: #FF5623;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 30px 30px;

    width: 40%;
    height: 400px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.map-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.map-heading h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #222;
    padding-bottom: 2rem;
    text-align: center;


}

.map-desc-container{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map-desc-container p{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;

    color: #ffffff;


}

.map-desc-container a{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #24FF00;


}

.app__header-button-2{
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
    background-image: linear-gradient(to right, #ffa600 0%, #1fd61f  51%, #ff7300  100%);
}


/* Extra Small Devices, Phones */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
    /* styles here */
    
}

@media only screen and (max-width: 992px) {

    .outer-container{
        width: 60%;
    }
    .map-desc-container p{
        font-size: 16px;
    }
}
@media only screen and (max-width: 576px) { 
    /* styles here */
    .outer-container{
        width: 80%;
    }

    .map-desc-container p{
        font-size: 12px;
        letter-spacing: 2px;
    }

    .map-heading h3{
        font-size: 20px;
    }
  }