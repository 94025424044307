.contact-form-section{
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    z-index: 0;
}

.side-tabs{
    display: flex;
    flex-direction: column;
}


.side-tabs .tabs{
    height: auto;
    width: 400px;
    background-color: #E3D3CD;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 7px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    text-align: left;
    padding: 2rem;
    cursor: pointer;
}

.side-tabs .tabs p{
    color: #1d1c1b;
    font-family: var(--font-inter);
    font-weight: 600;
    font-size: 15px;
    margin-left: 1rem;
    
}
.side-tabs button{
    border: none;
    
}

.middle-border{
    height: 300px;
    border: 1px solid rgb(245, 151, 122);
    margin-right: 2rem;
    margin-left: 2rem;
}
.contact-form{
    width: 100%;
}

.submit-button{
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.submit-button .btn{
    float: right;
    /* width: 100%; */
    background-color: #FF5623;
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
}
.submit-button .btn:hover{
    cursor: pointer;
}
.submit-button .btn:active {
    transform: translateY(1px); /* move the button down by 1 pixel */
    box-shadow: none; /* remove any box-shadow applied */
  }
.form-section{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.form-section p{
    font-family: var(--font-inter);
    /* font-size: 14px; */
    color: #888888;
    
    margin-right: 1rem;
}
.message-section p{
    font-family: var(--font-inter);
    /* font-size: 14px; */
    color: #888888;
}
.form-section input{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid rgb(245, 151, 122);
    /* margin-right: 2rem; */
    /* margin-left: 1rem; */
    width: 100%;
}
.form-section input:focus{
    /* border: none; */
    outline: none;
}
.message-section textarea{
    padding: 10px;
    margin-top: 1rem;
    resize: none;
    border: 2px solid rgb(245, 151, 122);
}

.message-section textarea:focus{
    outline: none;
    
}

/* .message-section input{
    border: 2px solid grey;
    width: 100%;
    height: 150px;
    margin-right: 1rem;
    margin-left: 1rem;
    
} */

.success-message{
    font-family: var(--font-inter);
    color: rgb(13, 247, 13);
    font-size: 16px;
}

/* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
    /* CSS rules here */
    .contact-form-section{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .side-tabs{
        width: 100%;
        /* display: none; */
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
    }
    .side-tabs .tabs{
        width: 100%;
        padding: 1rem;
    }
    .side-tabs .tabs p{
        word-wrap: break-word;
        width: 100%;
        padding:0 1rem;
        font-size: 12px;
    }

    .middle-border{
        display: none;
    }

    .contact-form{
        width: 100%;
    }

    .contact-form form .message-section textarea{
        width: 100%;
    }

    .contact-form form .submit-button .btn{
        width: 100%;
    }

    
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media only screen and (min-width: 576px) {
    /* CSS rules here */
    .contact-form-section{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .side-tabs{
        width: 100%;
        /* display: none; */
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
    }
    .side-tabs .tabs{
        width: 100%;
        padding: 1rem;
    }

    

    .middle-border{
        display: none;
    }

    .contact-form{
        width: 100%;
    }

    .contact-form form .message-section textarea{
        width: 100%;
    }

    .contact-form form .submit-button .btn{
        width: 100%;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* CSS rules here */
    .contact-form-section{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .side-tabs{
        width: 100%;
        /* display: none; */
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
    }
    .side-tabs .tabs{
        width: 100%;
        padding: 1rem;
    }

    .middle-border{
        display: none;
    }

    .contact-form{
        width: 100%;
    }

    .contact-form form .message-section textarea{
        width: 100%;
    }

    .contact-form form .submit-button .btn{
        width: 100%;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* CSS rules here */
   

  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* CSS rules here */
    .contact-form-section{
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .side-tabs{
        width: 100%;
        /* display: none; */
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
    }
    .side-tabs .tabs{
        width: 100%;
        padding: 1rem;
    }

    .middle-border{
        display: block;
    }

    .contact-form{
        width: 100%;
    }

    .contact-form form .message-section textarea{
        width: 100%;
    }

    .contact-form form .submit-button .btn{
        width: 100%;
    }
  }
  