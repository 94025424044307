/* body{
    background-color: orange;
} */
.outer-navbar-section{
    overflow: hidden;
}
.inner-navbar-section{
    width: 100%;
    /* padding: 0 3rem; */
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top: 2px solid rgb(185, 174, 174);
    overflow: auto;
}

.inner-navbar-section::-webkit-scrollbar{
    background-color: transparent;
}

.sticky{
    position: fixed;
    top: 90px;
}
.inner-navbar-section ul{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.inner-navbar-section ul .navbar-li{
    border-right: 2px solid rgb(201, 193, 193);
}

.inner-navbar-section ul li{
    list-style: none;
    
    width: 100%;
    /* border-left: 1px solid grey; */
    
    padding-left: 1rem;
    padding-right: 1rem;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.inner-navbar-section ul li a{
    font-family: var(--font-inter);
    color: var(--color-orange);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}