.app__header{
    background-image: url('../../../assets/newheader.png');
    /* background-repeat: no-repeat; */
    height: 550px;
    display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6rem;
    
}

.app__header-h1{
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    /* line-height: 48px; */
    color: #EB3223;
    letter-spacing: 0.03em;
    margin-bottom: 1rem;
}


.app__header-span{
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    color:  #1cd300;
}

.app__header-p{
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #272727;
    /* text-align: center; */
}

.app__header-buttons{
    margin-top: 50px;
    display: flex;
    
}

.app__header-buttons button{

    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
}
.app__header-buttons button:hover{
    cursor: pointer;
}

.app__header-button-1{
    /* background-color: #FF5623; */
    /* background-image: linear-gradient(to right, #ff5500 0%, #d61f34  51%, #ff2a00  100%); */
    background-color: #FF5623;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app__header-button-1:hover{
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.app__header-button-2{
    /* background-color: #1cd300; */
    background-image: linear-gradient(to right, #ffa600 0%, #1fd61f  51%, #ff7300  100%);

}


#header-img{
    position: absolute;
    width: 303px;
    height: 299px;
left: 850px;
top: 250px;
}

#header-img-2{
    position: absolute;
width: 303px;
height: 299px;
left: 940px;
top: 150px;
}
.location-heading{
    font-family: var(--font-base);
    font-size: 20px;
    /* color: #746d6a; */
    background-image: linear-gradient(to right, #cf9b2a 0%, #ff3c00  51%, #7da50e  100%);
    background-clip: text;
    -webkit-text-fill-color:transparent;
    font-weight: 800;
  }

@media only screen and (max-width: 300px) {
    .image-slider{
        display: none;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 576px) { 
    .app__header{
       padding: 5rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }
    .app__wrapper_info h1, .app__wrapper_info p{
        text-align: center;
    }

    .app__wrapper_info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .app__wrapper_info .app__header-h1{
        font-size: 20px;
    }

    .app__wrapper_info .app__header-p{
        font-size: 16px;
    }

    .app__wrapper_info .app__header-span{
        font-size: 20px;
    }

    .app__wrapper_info .app__header-buttons button{
        padding: 1rem 1rem;
    }

    .image-slider{
        /* display: none; */
        margin-top: 2rem;
    }
    
  }
  
  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    

    
    .image-slider{
        /* display: none; */
        margin-top: 2rem;

    } 
}
  
  /* Medium Devices, Desktops */
  @media only screen and (max-width: 992px) {
   
    .app__header{
        padding: 5rem 1rem;
        display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: auto;
     }
     .app__wrapper_info h1, .app__wrapper_info p{
         text-align: center;
     }

     .app__header-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
     }
    .image-slider{
        /* display: none; */
        margin-top: 2rem;

    }
  }
  
  /* Large Devices, Wide Screens */
  @media only screen and (max-width: 1200px) {
    /* styles here */
    .app__header{
        padding: 5rem 1rem;
        display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: auto;
     }
    .app__wrapper_info h1{
        font-size: 40px;
    }

    .app__wrapper_info h1, .app__wrapper_info p{
        text-align: center;
    }

    .app__header-buttons{
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .image-slider{
        margin-top: 2rem;

    }
    
  }



