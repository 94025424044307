.modal-pop-up{
    position: absolute;
    width: auto;
    height: auto;
    /* border: 1px solid black; */
    background-color: white;
    margin:3rem auto;
    padding: 2rem 1rem;
    border-radius: 20px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
    /* z-index: 1; */
    position: 'absolute';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: '#fff';
        z-index: 2;
}
.close-pop-up{
    display: none;
}
.inner-pop-up{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.modal-pop-up .modal-pop-up-close{
    float: right;
    font-size: 20px;
    color: var(--color-orange);
    cursor: pointer;
}

.modal-pop-up .inner-pop-up .modal-pop-up-heading{
    font-family: var(--font-inter);
    color: var(--color-orange);
    font-size: 30px;
    margin-bottom: 1rem;
}

.modal-pop-up .inner-pop-up .modal-pop-up-tab{
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    background-color: bisque;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);

    margin: 1rem 0;
}

.modal-pop-up .inner-pop-up .modal-pop-up-tab .pop-up-tab-text{
    font-family: var(--font-inter);
    color: #5f5b5b;

}

@media screen and (max-width:576px){

    .modal-pop-up{   
        width: 90%; 
        /* margin: 0 2rem; */
        text-align: center;
    }
}

@media screen and (min-width: 576px){

    .modal-pop-up{   
        width: 90%; 
        /* margin: 0 2rem; */
        text-align: center;
    }
}
  
  /* Medium devices (tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* CSS rules here */
    .modal-pop-up{   
        width: 50%; 
        /* margin: 0 2rem; */
        text-align: center;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* CSS rules here */
    .modal-pop-up{   
        width: 30%; 
        /* margin: 0 2rem; */
        text-align: center;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* CSS rules here */
    .modal-pop-up{   
        width: 30%; 
        /* margin: 0 2rem; */
        text-align: center;
    }
  }