.outer-cta-div{
    /* width: 100%; */
    height: 300px;
    background-color: #a8d825;
    margin: 6rem;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* background-image: url('../../assets/newheader.png'); */
}

.outer-cta-div .outer-right img{
    position: relative;
    width: 250px;
    height: 271.67px;
    /* left: 897px; */
    /* top: 70px; */
    bottom: 70px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px 20px 0px 0px;
}

.cta-button{
    /* margin: 10px; */
    padding: 15px 35px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #4b4a4a;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: bold;
    width: 200px;
    font-family: var(--font-inter);
    /* background-image:linear-gradient(to right, #ff5500 0%, #d61f34  51%, #ff2a00  100%); */
    background-color: #FF5623;
}

.cta-button:hover{
    background-position: right center; 
    color: #fff;
    text-decoration: none;
}

.cta-button:active{
    padding: 10px;
}

.outer-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.outer-left h1{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    /* line-height: 48px; */
    width: 80%;
    /* color: rgb(63, 58, 58); */
    color: #222;
}






/* Large Devices, Wide Screens */
  @media only screen and (max-width: 1200px) {
    /* styles here */
    .outer-cta-div{
        /* height: 330px; */
    }
     .outer-cta-div .outer-left{
         display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .outer-cta-div .outer-left h1{
        font-size: 30px;
        width: 90%;
        margin-bottom: 1rem;
    }
    /*
    .outer-cta-div .outer-left button{
        padding: 10px ;
    }
    
    .outer-cta-div .outer-right img{
        position: relative;
        width: 200px;
        height: 200px;
        bottom: 50px;
        
    } */
    
}
@media only screen and (max-width: 992px) {
    /* styles here */
    .outer-cta-div{
        height:230px;
    }
  .outer-cta-div .outer-left h1{
      font-size: 20px;
      
      margin-bottom: 1rem;
    }
    .outer-cta-div .outer-right img{
      position: relative;
      width: 200px;
      height: 200px;
      bottom: 50px;
      
    }
  .outer-cta-div .outer-left button{
      padding: 10px ;
      /* margin-top: 2rem; */
    }
}






/* Medium Devices, Desktops */
@media only screen and (max-width: 768px) {
  /* styles here */
  .outer-cta-div{
      height:200px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .outer-cta-div .outer-left{
        padding-top: 1rem;
    }
  .outer-cta-div .outer-left h1{
      font-size: 16px;
      margin-bottom: 1rem;
  }
  .outer-cta-div .outer-right img{
      position: relative;
      width: 150px;
      height: 150px;
      bottom: 50px;
      
    }
    .outer-cta-div .outer-left button{
        padding: 7px ;
        font-size: 14px;
        width: 150px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) { 
    /* styles here */
    .outer-cta-div{
        
        height:150px;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 6rem 1rem;
        
      }
  
      .outer-cta-div .outer-left{
          padding-top: 10px;
          padding-left: 10px;
      }
    .outer-cta-div .outer-left h1{
        font-size: 12px;
        margin-bottom: 1rem;
    }
    .outer-cta-div .outer-right img{
        position: relative;
        width: 100px;
        height: 120px;
        bottom: 50px;
        
    }
    .outer-cta-div .outer-left button{
        padding: 5px ;
        font-size: 10px;
        width: 100px;
    }
  }
  

  