.menucard-outer{
    margin: 1rem;
}


.menucard-inner{
    /* display:block; */

  background:white;
  position:relative;
  margin-top:100px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
}

.combo-height{
    width: 205px;
    height: 259px;
}

.burger-height{
    width: 300px;
    height: 100%;
    flex-direction: column;
    background:white;
    /* position:relative; */
    /* margin-top:100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2rem;
    
}

.wings-height{
    width: 205px;
    height: 180px;
}

.kidsmeal-height{
    width: 250px;
    height: 200px;
}

.menu-image{
    position:absolute;
    width: 150px;
height: 150px;
    border-radius:300px;
    /* border:3px solid white; */
    /* left:50%; */
    /* margin-left:-25px; */
    top: -70px;
      background:white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.burger-image{
    /* position:absolute; */
    width: 200px;
    height: 200px;
    
      background:white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dessert-image{
    position:absolute;
    width: 150px;
height: 150px;
    border-radius:300px;
    /* border:3px solid white; */
    /* left:50%; */
    /* margin-left:-25px; */
    top: -70px;
      background:white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.burger-image img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    /* border-radius: 300px; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.menu-image img{
    width: 120px;
    height: 120px;
    border-radius: 300px;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dessert-image img{
    width: 120px;
    height: 120px;
    border-radius: 300px;
    /* object-fit: cover; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    padding: 1rem;
    margin-top: 4rem;
}

.menu-desc p{
    font-family: var(--font-inter);
font-style: normal;
/* font-weight: 800; */
/* font-size: 18px; */
line-height: 22px;

text-align: center;

/* color: #000000; */
}

.burger-desc-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    padding: 1rem;
}
.burger-desc-container p{
    text-align: center;
    font-family: var(--font-inter);
}
.combo-price{
    font-size: 20px;
    font-family: var(--font-inter);
    font-weight: 700;
    color: green;
}

.burger-name{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #222;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.combo-desc{
    /* text-transform: capitalize; */
    color: #FF5623;
    font-size: 18px;
    font-weight: 600;

}

.burger-desc-container .combo-desc{
    line-height: 1.5;
}

.burger-desc{
    text-transform: capitalize;
    font-size: 18px;
    color: #FF5623;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

