.gallery-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    width: 100%;
    overflow: hidden;
}
.gallery-header section{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 3rem; */
    width: 100%;
}
.gallery-header h1{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    /* background-image: linear-gradient(45deg, #CA571E, #C22F41);
  background-clip: text;
  -webkit-text-fill-color:transparent; */
    text-align: center;
    margin-bottom: 2rem;
}

.flavour-type{
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    color: #FF5623;
    
}

.flavour-tabs{
    padding-left: 4rem;
    padding-right: 4rem;
    /* padding-top: 2rem; */
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    /* width: 80%; */
    justify-content: center;
}

.divider{
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(165, 165, 165);
    width: 80%;
    margin-bottom: 4rem;
}

.combo-tabs{
    /* width: 90%; */
    padding-left: 4rem;
    padding-right: 4rem;
    /* padding-top: 2rem; */
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    /* width: 80%; */
    justify-content: center;
    /* display: flex;
    flex-wrap: wrap; */
    gap: 10px;
    overflow-x: hidden;
}


.prev, .next {
    cursor: pointer;
    height: 50px;
    width: 50px;
    background-color: transparent;
    transition: 0.6s ease;
    /* user-select: none; */
    border: none;
    border-radius: 100px;
   
  }
  
  /* Position the "next button" to the right */
  .next {
    /* right: 0; */
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  }

  .combo-slider{
    display: flex;
    /* width: 90%; */
    align-items: center;
    justify-content: center;
  }


  .add-button-div{
    width: 80%;
  }

  .add-button-div button{
    float: right;
    background-color: transparent;
    border-radius: 200px;
    width: 30px;
    height: 30px;
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .add-button-div button:hover{
    box-shadow:  0px 8px 8px rgba(0, 0, 0, 0.25);
  }

  .add-button-div button:active{
    width: 28px;
    height: 28px;
  }


  .scroll-to-top-button {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    background-color: transparent;
  }
  .color-circles{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .color-circles .circle{
    width: 2rem;
    height: 2rem;
    border-radius: 5rem;
    /* background-color: #FF5623; */
    margin: 0 1rem;
  }
  .color-circles .circle-tags{
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
  }
  .color-circles .tag1{
    color: #FF5623;
  }

  .color-circles .tag2{
    color: yellowgreen;
  }
  .color-circles  .red{
    background: #D00000;
}

.color-circles .orange{
    background: #FF3B00;
}

.color-circles .mustard{
    background: #FF8933;
}

.color-circles .yellow{
    background: #FFC123;
}

.color-circles .green{
    background-color: #A0D800;
}
 
  /* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
  /* CSS rules here */
  .gallery-header h1{
    font-size: 30px;
  }

}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {
  /* CSS rules here */
  .gallery-header h1{
    font-size: 30px;
  }

}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* CSS rules here */
  .gallery-header h1{
    font-size: 48px;
  }

}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* CSS rules here */
  .combo-slider{
    width: 70%;
  }

  .flavour-tabs{
    width: 80%;
  }

}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* CSS rules here */
  .combo-slider{
    width: 90%;
  }
  .flavour-tabs{
    width: 100%;
  }
}
