.contact-section{
    height: auto;
    width: 100%;
    background-image: url('../../../assets/newheader.png');
    display: flex;
    justify-content: center;
    /* overflow: hidden; */
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.inner-form{
    /* margin-top: 8rem; */
    margin-left: 6rem;
    margin-right: 6rem;
    width: 80%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: auto;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-form h1{
  font-family: var(--font-inter);
  font-size: 40px;
  color: #FF5623;
  font-weight: 700;
  margin-bottom: 1rem;
}

.contact-page-tagline{
  font-family: var(--font-inter);
  font-size: 20px;
  color: #222;
  font-weight: 600;
  text-align: center;
}

.inner-form-links{
    display: flex;
    justify-content: flex-start;
}

.inner-form-links li{
    text-decoration: none;
    list-style: none;
    margin-right: 1rem;
    margin-left: 1rem;
    font-weight: 600;
}

.bottom-border{
    width: 100%;
    border: 1px solid rgb(245, 151, 122);
    margin-top: 10px;
}


/* *, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  } */
  
  /* body {
    background: #fff;
  } */
  
  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 500px;
    height: 300px;
    background: #f1f1f1;
    margin: 100px auto 0;
    word-break: break-all;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .bloc-tabs {
    display: flex;
  }
  .navtabs {
  
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  .navtabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: #FF5623;
  }
  
  
  
  button {
    border: none;
    font-family: var(--font-inter);
    font-weight: 600;
    font-size: 16px;
    color: #FF5623;
    
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    /* width: 100%;
    height: 100%; */
  }
  .active-content {
    display: block;
  }

  /* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
  /* CSS rules here */
  .inner-form h1{
    font-size: 16px;
  }

  .contact-page-tagline{
    font-size: 14px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {
  /* CSS rules here */
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* CSS rules here */
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* CSS rules here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* CSS rules here */
}
