.footer-section{
    /* height: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content:space-between;
    padding-left: 6rem;
    padding-right: 8rem;
    background-color: #D9D9D9;
    border-top: 3px solid white; */
    padding: 1rem 6rem;
    
}
.color{
    background-color: white;
}

.menu-color{
    background-color: #E3D3CD;
}

.image{
    background-image: url('../../../assets/newheader.png');

}

.inner-footer-section{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #CA571E;
    padding-bottom: 1rem;
}

.header-div h1{
    font-family: 'Shantell Sans', cursive;
    background-image: linear-gradient(45deg, #CA571E, #C22F41);
    background-clip: text;
    -webkit-text-fill-color:transparent;
    cursor: pointer;
}
.header-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-div p{
    margin-left: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #000000;
}
.footer-section .footer-1 .socials-icons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-1 button{
    /* margin: 10px; */
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
}

.footer-1 .header-div button{
    background-color: var(--color-orange);
    padding: 7px;
    font-size: 15px;
    margin-left: 10px;
}
.footer-1 .buttons-div .button-1{
    background-color: var(--color-orange);
}

.footer-1 .buttons-div .button-2{
    background-image: linear-gradient(to right, #ffa600 0%, #1fd61f  51%, #ff7300  100%);
}
button:hover{
    cursor:pointer;
}

.header-div{
    display: flex;
    align-items: center;
}
.locations-div{
    display: flex;
    align-items: center;
}
.locations-div p{
    font-family: 'Podkova';
    font-weight: 500;
    margin-right: 10px;
}

.locations-div div{
    height: 17px;
    border-right: 2px solid rgb(104, 103, 103);
    margin-right: 7px;
    margin-left: 7px;
}

.locations-div a{

    font-family: 'Podkova';
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #ff3c00;


}

.buttons-div{
    display: flex;
    margin-top: 30px;
}

.footer-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-2 .socials-link a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-decoration-line: underline;
    color: #000000;
    display: flex;
    justify-content: center;
}
.socials-link{
    padding-bottom: 20px;
}

.footer-2 .socials-icons{
    display: flex;
}

.insta-icon{
    margin-right: 30px;
    margin-left: 30px;
}

.footer-3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* p,a{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #000000;
} */

.social-icon{
    opacity:0.5;
}

.social-icon:hover{
    opacity:1;
    cursor:pointer;
}

/* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
    /* CSS rules here */
    .footer-section{
        padding: 1rem 1rem;
    }

    .inner-footer-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin-bottom: 1rem; */
    }
    .header-div h1{
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .header-div{
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .social-icon{
        font-size: 20px;
    }
    .social-icons{
        display: flex;
        flex-direction: row;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media only screen and (min-width: 576px) {
    /* CSS rules here */
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* CSS rules here */
  }
  
  /* Large devices (desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* CSS rules here */
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* CSS rules here */
  }
  