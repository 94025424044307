.subheading{
    /* font-family: var(--font-base);
  background-image: linear-gradient(to right, #e7a924 0%, #FF5623  51%, #b3e724  100%);
  background-clip: text;
  -webkit-text-fill-color:transparent;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 85px;
  line-height: 94px; */
}



/* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
    /* CSS rules here */
    .subheading-div .subheading{
        font-family: var(--font-base);
        background-image: linear-gradient(to right, #e7a924 0%, #FF5623  51%, #b3e724  100%);
        background-clip: text;
        -webkit-text-fill-color:transparent;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 40px;
        line-height: 94px;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media only screen and (min-width: 576px) {
    /* CSS rules here */
    .subheading-div .subheading{
        font-family: var(--font-base);
        background-image: linear-gradient(to right, #e7a924 0%, #FF5623  51%, #b3e724  100%);
        background-clip: text;
        -webkit-text-fill-color:transparent;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 85px;
        line-height: 94px;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* CSS rules here */
    .subheading-div .subheading{
        font-family: var(--font-base);
        background-image: linear-gradient(to right, #e7a924 0%, #FF5623  51%, #b3e724  100%);
        background-clip: text;
        -webkit-text-fill-color:transparent;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 85px;
        line-height: 94px;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* CSS rules here */
    .subheading-div .subheading{
        font-family: var(--font-base);
        background-image: linear-gradient(to right, #e7a924 0%, #FF5623  51%, #b3e724  100%);
        background-clip: text;
        -webkit-text-fill-color:transparent;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 85px;
        line-height: 94px;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* CSS rules here */
    .subheading-div .subheading{
        font-family: var(--font-base);
        background-image: linear-gradient(to right, #e7a924 0%, #FF5623  51%, #b3e724  100%);
        background-clip: text;
        -webkit-text-fill-color:transparent;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 85px;
        line-height: 94px;
    }
  }
  