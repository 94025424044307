.outer-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    margin-left: 2rem;
    /* background-image: linear-gradient(to right, #ff5500 0%, #d61f34  51%, #ff2a00  100%); */
    cursor: pointer;
    /* padding-bottom: 20px; */
}

.menu-item-image{

    width: 250px;
    /* height: 200px; */
    /* height: 250px; */
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.menu-item-name{
    height: 50px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-image: linear-gradient(to right, #b3e724 0%, #FF5623  51%, #e4a11b  100%);
}

.menu-item-name p{

font-family: var(--font-inter);
font-style: normal;
font-weight: 700;
font-size: 16px;

color: #FFFFFF;


}
