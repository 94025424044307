.menu-intro{
    height: 400px;
    background-image: url('../../../assets/newheader.png');
    /* margin-bottom: 3rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-intro img{
  width: 7rem;
}

.left-div, .right-div{
    height: 20px;
    width: 100px;
    border-top: 3px solid var(--color-orange);
    border-bottom: 3px solid var(--color-orange);
    margin-right: 1rem;
    margin-left: 1rem;
}

.border-divs{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.fork-icon{
    font-size: 30px;
}

/* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
    /* CSS rules here */
    .menu-intro{
        height: 200px;
    }

    .left-div, .right-div{
        height: 10px;
        width: 50px;
        /* border-top: 3px solid var(--color-orange);
        border-bottom: 3px solid var(--color-orange); */
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .fork-icon{
        font-size: 20px;
    }
  }
  
  
 /* Extra small devices (phones, up to 576px) */
@media only screen and (max-width: 576px) {
  /* CSS rules here */
  .menu-intro{
    height: 450px ;
  }

  .menu-intro img{
    width: 5rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {
  /* CSS rules here */
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* CSS rules here */
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* CSS rules here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* CSS rules here */
}
