.main-employment-form h2{
    font-family: var(--font-inter);
    color: #FF5623;

}


.inner-employment-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.first-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.second-row{
    display: flex;
    width: 50%;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.employment-form-section{
    display: flex;
}

.employment-form-section input[type=text]{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid rgb(245, 151, 122);
    margin-right: 2rem;
    margin-left: 1rem;
    width: 100%;
}
.employment-form-section .option p{
    margin-bottom: 10px;
}
.employment-form-section textarea{
    padding: 10px;
    /* margin-top: 1rem; */
    margin-right: 2rem;
    margin-left: 1rem;
    resize: none;
    border: 2px solid rgb(245, 151, 122);
}
.employment-form-section select{
    margin-right: 2rem;
    margin-left: 1rem;
    resize: none;
    border: 2px solid rgb(245, 151, 122);
    height: 30px;
    width: 100%;
}
.employment-form-section input:focus{
    outline: none;
}
.employment-form-section select:focus{
    outline: none;
}
.employment-form-section textarea:focus{
    outline: none;
}
.employment-form-section p,label{
    font-family: var(--font-inter);
    /* font-size: 14px; */
    color: #888888;
}

.third-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.third-row .employment-form-section textarea{
    /* width: 100%; */
}
/* .first-row input{
    margin-left: 1rem;
    margin-right: 1rem;
} */
/* .fourth-row{
    display: flex;
    justify-content: flex-end;
    
} */
.fourth-row button{
    /* float: right; */
    /* width: 100%; */
    background-color: #FF5623;
    margin: 10px;
    padding: 10px 35px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
}