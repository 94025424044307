.about_us_section{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 700px; */
    margin-bottom: 4rem;
    /* max-width: 300px; */
    
}

.about_us_heading{
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #383838;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;

}

.menu-items{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    /* margin-bottom: 50px; */

}


.aboutus__header-button-1{
    /* background-color: #FF5623; */
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #888888;
    border-radius: 10px;
    display: block;
    border: none;
    font-weight: bold;
    font-family: var(--font-inter);
    background-image: linear-gradient(to right, #b3e724 0%, #FF5623  51%, #e4a11b  100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.app__header-button-1:hover{
    
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.menu-button{
    margin-top: 30px;
}
/* Extra Small Devices, Phones */

@media only screen and (max-width: 576px) { 
    /* styles here */
    .about_us_section{
        margin-top: 3rem;
    }
    
  }
  
  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    .about_us_section{
        margin-top: 3rem;
    }
    .menu-items{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .menu-items div{
        margin-top:7px;
    margin-bottom: 7px;

    }

    
  }
  
  /* Medium Devices, Desktops */
  @media only screen and (max-width: 992px) {
    .about_us_section{
        margin-top: 3rem;
    }
    .menu-items{
        justify-content: center;
    }
    .menu-items div{
        margin-right:10px;
    margin-left: 10px;

    }

    .menu-items img{
        width: 200px;
    }

    .menu-items div div{
        width: 200px;
    }
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width: 1200px) {
    /* .about_us_section{
        margin-top: 3rem;
    } */
    /* styles here */
    .about_us_section .about_us_heading{
        font-size: 20px;
    }

    

    
  }